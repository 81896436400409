import React, { useEffect } from 'react';
import { Breadcrumb } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearFetchObject } from '../../../features/fetch_id/fetchIdSlice';
import { clearNatureOfBusiness } from '../../../features/fetch/fetchSlice';
import BlackNav from '../../../components/BlackNav';

const items = [
	{
		title: <Link to='/services'>Services</Link>,
	},
	{
		title: 'Application for Name clearance',
	},
];

export default function NameClearanceOverviewPage() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	function handleApply() {
		navigate('/services/company-forms/name-clearance/application');
	}

	function handleClearState() {
		dispatch(clearNatureOfBusiness());
		dispatch(clearFetchObject());
	}

	useEffect(() => {
		handleClearState();
	}, []);

	return (
		<>
			<BlackNav text={'Patents and Companies Registration Agency'} />
			<div className='mt-[.94rem] bg-white py-[2.64rem] px-[2.5rem]'>
				<Breadcrumb items={items} />

				<div className='heading_4 mt-[1.94rem] !text-blk21 capitalize'>
					Application for Name clearance
				</div>
				<div className='label_1 mt-[1.69rem]'>Overview</div>

				<div className='label_2 mt-[2.5rem] flex flex-col'>
					Name clearance is a process of checking for the existence of a
					proposed company or business name. This is done to ensure that the
					proposed company or business accepted for registration does not exist
					or is not confusingly similar or misleading to the public.
					<span className='label_1 mt-[1rem]'>PREREQUISITE</span>
					<ol className='list-decimal mt-[.5rem] ml-[1rem]'>
						<li>
							Name clearance guidelines: The following names are not allowed;
						</li>

						<ol
							style={{
								listStyleType: 'lower-alpha',
							}}
							className='ml-[1rem]'>
							<li>A name that is too long and difficult to pronounce.</li>
							<li>
								A name that suggest that the applicant enjoys the patronage of
								the President.
							</li>
							<li>
								A name that is confusingly similar to what already exists or the
								same as a well-known name.
							</li>
							<li>
								Offensive or undesirable names. This means that a name should
								not be in form of an insult as this is against public policy.
							</li>
							<li>Deceptive or misleading names.</li>
							<li>Use of the word Zambia as a prefix</li>
						</ol>

						<li>
							Name Clearance can be processed from any nearest PACRA office or
							on the website at www.pacra.org.zm
						</li>
					</ol>
					<span className='label_1 mt-[1rem]'>PROCEDURE</span>A maximum of three
					names can be proposed. The names will be considered in the order of
					listing. If the first choice is turned down, the second or third name
					may be considered. Once the name is accepted, it is automatically
					reserved for a period of 30 days. After 30 days the name clearance
					expires.
					<span className='label_1 mt-[1rem]'>FEES</span>
					<ol
						style={{
							listStyleType: 'lower-alpha',
						}}
						className='ml-[1rem]'>
						<li>Business Name – K83 </li>
						<li>Local Companies – K90</li>
						<li>Foreign Companies– K200</li>
					</ol>
				</div>

				<div
					style={{
						width: '100%',
						height: '11.125rem',
						display: 'flex',
						padding: '1.25rem',
						alignItems: 'center',
						justifyContent: 'center',
						gap: '1rem',
						flex: '1 0 0',
						border: '1px solid var(--Border-secondary, #ECECEC)',
						background: '#FFF',
					}}
					className='mt-[1.25rem]'>
					<div className='flex flex-col w-fit'>
						<button onClick={handleApply} className='cstm-btn'>
							Apply
						</button>
						<button
							onClick={() => navigate('/services')}
							className='cstm-btn-borderless'>
							Back to services
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
