import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export default function NatureTab() {
	const { natureOfBusiness } = useSelector((state) => state.fetch);

	let natureOfBusinessCopy = [...natureOfBusiness];

	useEffect(() => {}, [natureOfBusiness]);

	return (
		<>
			<div className='mt-[.94rem] bg-white px-[2.5rem] w-full'>
				<div className='mt-[3.75rem] border border-[#EAECF0]'>
					<div className='!grid !grid-cols-2 application_details_header'>
						<span className='label_2'>Longitutes</span>
						<span className='label_2'>Latitudes</span>
					</div>
					<div className='grid grid-cols-2'>
						{natureOfBusinessCopy?.map((item) => {
							return (
								<>
									<div className='flex items-center application_details_body'>
										<span className='paragraph_2 col-span-2 w-full'>
											{item?.locLongitude}
										</span>
									</div>

									<div className='flex items-center ml-[.5rem]'>
										<span className='col-span-2 w-full'>
											{item?.locLatitude}
										</span>
									</div>
								</>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
}
