import { Breadcrumb, Tabs } from 'antd';
import BlackNav from '../../../components/BlackNav';
import NameClearanceOrderSummary from '../name_clearance/NameClearanceOrderSummary';
import NameClearanceHelp from '../name_clearance/NameClearanceHelp';
import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import Payment from '../../payment/Payment';
import ReservationApplicationStep from './steps/ReservationApplicationStep';
import { useDispatch } from 'react-redux';
import { fetchClearedNames } from '../../../features/fetch/fetchSlice';

const items = [
	{
		title: <Link to='/services'>Services</Link>,
	},
	{
		title: (
			<Link to='/services/company-forms/name-reservation'>
				Application for Name reservation
			</Link>
		),
	},
	{
		title: 'Application Form',
	},
];

export default function ApplicationForNameReservation() {
	const dispatch = useDispatch();

	const view = useRef();

	const [current, setcurrent] = useState('1');

	function handleNext() {
		console.log(current);
		let num = Number(current);
		if (num < 3) {
			num += 1;
			setcurrent(String(num));
			view?.current?.scrollIntoView();
		} else {
			setcurrent(current);
		}
	}

	function handleBack() {
		let num = Number(current);
		if (num > 0) {
			num -= 1;
			setcurrent(String(num));
			view?.current?.scrollIntoView();
		} else {
			setcurrent(current);
		}
	}

	async function handleFetchClearedNames() {
		await dispatch(fetchClearedNames());
	}

	const tabItems = [
		{
			label: 'Name Selection',
			key: '1',
			children: <ReservationApplicationStep handleNext={handleNext} />,
		},

		{
			label: 'Payment',
			key: '2',
			children: <Payment canGoBack={true} handleBack={handleBack} />,
		},
	];

	useEffect(() => {
		handleFetchClearedNames();
	}, []);

	return (
		<>
			<BlackNav text={'Patents and Companies Registration Agency'} />
			<div className='flex justify-start items-start mt-[.94rem] w-full'>
				<div className='bg-white py-[2.64rem] px-[2.5rem] h-full w-full'>
					<div className='flex flex-col w-full'>
						<Breadcrumb items={items} />
						<span ref={view} className='heading_4 !text-blk21 mt-[1.94rem]'>
							The Companies Act, 2017 (Act No. 10 of 2017)
						</span>

						<span className='label_1 !text-blk21 mt-[1.69rem]'>
							The Companies (Prescribed Forms) Regulations, 2018 (Section 41)
						</span>

						<div className='p-[2.5rem] border border-[#ECECEC] mt-[2.5rem] w-full'>
							<Tabs
								activeKey={current}
								defaultActiveKey='1'
								items={tabItems}
								// onChange={onChange}
							/>
						</div>
					</div>
				</div>

				<div className='hidden lg:flex lg:min-w-[30.625rem] lg:w-[30.625rem] max-w-full bg-white h-full py-[3.75rem] px-[2.5rem] ml-[.94rem]'>
					{current === '2' ? (
						<NameClearanceOrderSummary />
					) : (
						<NameClearanceHelp />
					)}
				</div>
			</div>
		</>
	);
}
