import { Suspense, useEffect, useState } from 'react';
import './App.css';
import { useSelector, useDispatch } from 'react-redux';
import {
	Navigate,
	Outlet,
	Route,
	Routes,
	useLocation,
	useSearchParams,
} from 'react-router-dom';
import { Puff } from 'react-loader-spinner';
import axiosInstance from './instance';
import { ProtectedRoute } from './ProtectedRoute';
import ApplicationOfNameClearance from './pages/company_forms/name_clearance/ApplicationOfNameClearance';
import DashBoard from './pages/dashboard/Dashboard';
import NameClearanceOverviewPage from './pages/company_forms/name_clearance/NameClearanceOverviewPage';
import ApplicationHistory from './pages/history/ApplicationHistory';
import ViewApplicationHistory from './pages/history/ViewApplicationHistory';
import Payment from './pages/payment/Payment';
import BillingForm from './pages/payment/BillingForm';
import { logout, setToken, setUser } from './features/auth/authSlice';
import { jwtDecode } from 'jwt-decode';
import MainProfile from './pages/profile/MainProfile';
import AccountSettings from './pages/profile/AccountSettings';
import VerifyPhone from './pages/profile/update_profile/update_phone/VerifyPhone';
import NewPhone from './pages/profile/update_profile/update_phone/NewPhone';
import VerifyNewPhone from './pages/profile/update_profile/update_phone/VerifyNewPhone';
import PhoneVerified from './pages/profile/update_profile/update_phone/PhoneVerified';
import VerifyEmail from './pages/profile/update_profile/update_email/VerifyEmail';
import NewEmail from './pages/profile/update_profile/update_email/NewEmail';
import VerifyNewEmail from './pages/profile/update_profile/update_email/VerifyNewEmail';
import EmailVerified from './pages/profile/update_profile/update_email/EmailVerified';
import CurrentPassword from './pages/profile/update_profile/update_password/CurrentPassword';
import VerifyNewPassword from './pages/profile/update_profile/update_password/VerifyNewPassword';
import NewPassword from './pages/profile/update_profile/update_password/NewPassword';
import PasswordVerified from './pages/profile/update_profile/update_password/PasswordVerified';
import NameReservationOverviewPage from './pages/company_forms/name_reservation/NameReservationOverviewPage';
import ApplicationForNameReservation from './pages/company_forms/name_reservation/ApplicationForNameReservation';

function App() {
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const { pathname } = useLocation();

	const { isLoggedIn, token } = useSelector((state) => state.auth);

	const [usrToken, setusrToken] = useState(searchParams.get('userId'));

	axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${
		token ?? usrToken
	}`;

	async function handleInit() {
		if (usrToken?.length > 0) {
			await dispatch(setToken(usrToken));
			axiosInstance.defaults.headers.common[
				'Authorization'
			] = `Bearer ${usrToken}`;
			const decoded = await jwtDecode(usrToken);
			await dispatch(setUser(decoded));
		}
	}

	const isTokenExpired = (token) => {
		if (!token && !usrToken) return true;

		const decodedToken = token?.length && jwtDecode(token);
		const currentTime = Date.now() / 1000;

		if (decodedToken?.exp < currentTime) {
			return true;
		}

		return false;
	};

	async function handleLogout() {
		await dispatch(logout());
		await localStorage.clear();
		window.location = await 'https://ecitizen.ndovupay.org/account/login';
	}

	const SuspenseLayout = () => (
		<Suspense
			fallback={
				<Puff
					visible={true}
					height='80'
					width='80'
					color='#4fa94d'
					ariaLabel='puff-loading'
					wrapperStyle={{}}
					wrapperClass=''
				/>
			}>
			<Outlet />
		</Suspense>
	);

	useEffect(() => {
		handleInit();
	}, []);

	useEffect(() => {}, [isLoggedIn]);

	useEffect(() => {
		axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	}, [token]);


	useEffect(() => {
		if (isTokenExpired(token)) {
			handleLogout();
		}
	}, [token, pathname]);

	return (
		<Routes>
			<Route element={<ProtectedRoute />}>
				<Route element={<SuspenseLayout />}>
					{/* <Route path='*' element={<Navigate to='/services' replace />} /> */}
					<Route path='' element={<DashBoard />} />
					<Route path='/services' element={<DashBoard />} />
					<Route
						path='/services/company-forms/name-clearance'
						element={<NameClearanceOverviewPage />}
					/>
					<Route
						path='/services/mining/application'
						element={<ApplicationOfNameClearance />}
					/>
					<Route
						path='/services/application-history'
						element={<ApplicationHistory />}
					/>
					<Route
						path='/services/application-history/view-application'
						element={<ViewApplicationHistory />}
					/>
					<Route path='/services/payment' element={<Payment />} />
					<Route path='/services/payment/billing' element={<BillingForm />} />

					<Route
						path='/services/company-forms/name-reservation'
						element={<NameReservationOverviewPage />}
					/>
					<Route
						path='/services/company-forms/name-reservation/application'
						element={<ApplicationForNameReservation />}
					/>

					<Route path='/profile' element={<MainProfile />} />
					<Route path='/account-settings' element={<AccountSettings />} />
					<Route
						path='/account-settings/verify-phone'
						element={<VerifyPhone />}
					/>
					<Route path='/account-settings/new-phone' element={<NewPhone />} />
					<Route
						path='/account-settings/verify-new-phone'
						element={<VerifyNewPhone />}
					/>
					<Route
						path='/account-settings/phone-update-complete'
						element={<PhoneVerified />}
					/>
					<Route
						path='/account-settings/verify-email'
						element={<VerifyEmail />}
					/>
					<Route path='/account-settings/new-email' element={<NewEmail />} />
					<Route
						path='/account-settings/verify-new-email'
						element={<VerifyNewEmail />}
					/>
					<Route
						path='/account-settings/email-update-complete'
						element={<EmailVerified />}
					/>

					<Route
						path='/account-settings/current-password'
						element={<CurrentPassword />}
					/>
					<Route
						path='/account-settings/verify-current-password'
						element={<VerifyNewPassword />}
					/>
					<Route
						path='/account-settings/new-password'
						element={<NewPassword />}
					/>
					<Route
						path='/account-settings/password-update-complete'
						element={<PasswordVerified />}
					/>
				</Route>
			</Route>
		</Routes>
	);
}

export default App;
