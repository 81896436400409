import React, { useEffect } from 'react';
import { Breadcrumb } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearFetchObject } from '../../../features/fetch_id/fetchIdSlice';
import { clearNatureOfBusiness } from '../../../features/fetch/fetchSlice';
import BlackNav from '../../../components/BlackNav';

const items = [
	{
		title: <Link to='/services'>Services</Link>,
	},
	{
		title: 'Application for Name Reservation',
	},
];

export default function NameReservationOverviewPage() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	function handleApply() {
		navigate('/services/company-forms/name-reservation/application');
	}

	function handleClearState() {
		dispatch(clearNatureOfBusiness());
		dispatch(clearFetchObject());
	}

	useEffect(() => {
		handleClearState();
	}, []);

	return (
		<>
			<BlackNav text={'Patents and Companies Registration Agency'} />
			<div className='mt-[.94rem] bg-white py-[2.64rem] px-[2.5rem]'>
				<Breadcrumb items={items} />

				<div className='heading_4 mt-[1.94rem] !text-blk21 capitalize'>
					Application for Name Reservation
				</div>
				<div className='label_1 mt-[1.69rem]'>Overview</div>

				<div className='label_2 mt-[2.5rem] flex flex-col'>
					A Name Reservation is a procedure through which a person or entity can
					reserve a desired business or company name with PACRA, ensuring that
					the name is not taken by someone else for a specified period. This is
					often the preliminary step before formally registering a business or
					company, especially if there's a delay anticipated between choosing a
					name and the actual company or business registration process. This
					service will reserve your cleared name for an additional 90 days. To
					get started click apply button below.
					<span className='label_1 mt-[1rem]'>FEES</span>
					<ol
						style={{
							listStyleType: 'lower-alpha',
						}}
						className='ml-[2rem]'>
						<li>Business Name ZMW 222.40</li>
						<li>Local Company ZMW 213.33</li>
						<li>Foreign Company ZMW 133.33</li>
					</ol>
				</div>

				<p className='paragraph_1 !text-[#6B7280] pt-[1.5rem]'>
					A transaction fee may be added depending on your mode of payment
				</p>

				<div
					style={{
						width: '100%',
						height: '11.125rem',
						display: 'flex',
						padding: '1.25rem',
						alignItems: 'center',
						justifyContent: 'center',
						gap: '1rem',
						flex: '1 0 0',
						border: '1px solid var(--Border-secondary, #ECECEC)',
						background: '#FFF',
					}}
					className='mt-[1.5rem]'>
					<div className='flex flex-col w-fit'>
						<button onClick={handleApply} className='cstm-btn'>
							Apply
						</button>
						<button
							onClick={() => navigate('/services')}
							className='cstm-btn-borderless'>
							Back to services
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
