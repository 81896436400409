import React, { useState } from 'react';
import NatureOfBusinessTable from '../table/NatureOfBusinessTable';
import NatureOfBusinessModal from '../modals/NatureOfBusinessModal';
import { useDispatch, useSelector } from 'react-redux';
import { save } from '../../../../features/save/saveSlice';
import { Spin, message } from 'antd';
import { fetchId } from '../../../../features/fetch_id/fetchIdSlice';

export default function NatureOfBusinessStep({ handleBack, handleNext }) {
	const dispatch = useDispatch();

	const { natureOfBusiness } = useSelector((state) => state.fetch);
	const { fetchObj } = useSelector((state) => state.fetchId);
	const { saving } = useSelector((state) => state.save);

	const [open, setopen] = useState(false);

	function handleCancel() {
		setopen(false);
	}

	async function handleFetchObj() {
		const responseObj = {
			url: `/v1/api/app/${fetchObj?.id}`,
		};
		await dispatch(fetchId(responseObj));
	}

	async function handleOnSubmit() {
		const obj = {
			...fetchObj,
			apfApplicationStatus: 'PAYMENT_OPTIONS',
			url: '/v1/api/app',
		};

		const res = await dispatch(save(obj));
		if (res?.payload?.success) {
			handleFetchObj();
			handleNext();
		} else {
			message.error('An error has occured while submitting your request');
		}
	}

	return (
		<>
			<div className='mt-[35px]'>
				<div className='mt-[40px] h-full mb-10'>
					<div className='w-full flex justify-end'>
						<button
							onClick={() => setopen(true)}
							className='w-fit py-1 px-3 border border-grn rounded-[1.75rem]'>
							Add Location
						</button>
					</div>
					<NatureOfBusinessTable />

					<div className='mt-[52px] flex items-center justify-center flex-col gap-y-[4px] mb-[40px]'>
						<div className='w-[184px]'>
							{/* <button
								type='button'
								onClick={() => handleNext()}
								className='cstm-btn'>
								Next
							</button> */}
							<button
								onClick={handleOnSubmit}
								disabled={natureOfBusiness?.length === 0 || saving}
								type='button'
								className='cstm-btn'>
									{
										saving ? <Spin /> : "Submit"
									}
								
							</button>
						</div>
						<div className='w-[184px]'>
							<button
								type='button'
								onClick={() => handleBack()}
								className='cstm-btn-borderless'>
								Previous
							</button>
						</div>
					</div>
				</div>
			</div>

			<NatureOfBusinessModal open={open} handleCancel={handleCancel} />
		</>
	);
}
