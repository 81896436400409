import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNatureOfBusiness } from '../../../../features/fetch/fetchSlice';
import { handleDelete } from '../../../../features/delete/deleteSlice';
import NatureOfBusinessModal from '../modals/NatureOfBusinessModal';

export default function NatureOfBusinessTable() {
	const dispatch = useDispatch();

	const { natureOfBusiness } = useSelector((state) => state.fetch);
	const { fetchObj } = useSelector((state) => state.fetchId);

	const [open, setopen] = useState(false);
	const [editObj, seteditObj] = useState({});

	function handleCancel() {
		setopen(false);
		seteditObj({});
	}

	function handleEdit(item) {
		seteditObj(item);
		setopen(true);
	}

	const handleFetchNatureOfBusiness = async () => {
		await dispatch(fetchNatureOfBusiness(fetchObj?.id));
	};

	async function handleRemove(obj) {
		const deleteObj = {
			url: `/v1/api/loc/${obj?.locId}`,
		};
		await dispatch(handleDelete(deleteObj));
		await handleFetchNatureOfBusiness();
	}

	const columns = [
		{
			title: 'Longitudes',
			dataIndex: 'locLongitude',
			width: '300px',
		},
		{
			title: 'Latitudes',
			dataIndex: 'locLatitude',
		},
		{
			title: 'Action',
			key: 'action',
			render: (item) => (
				<div className='flex items-center'>
					<button onClick={() => handleEdit(item)} className=''>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='25'
							viewBox='0 0 33 32'
							fill='none'>
							<path
								d='M12.0745 27.0002H6.48828C6.22306 27.0002 5.96871 26.8948 5.78117 26.7073C5.59364 26.5197 5.48828 26.2654 5.48828 26.0002V20.4139C5.4884 20.149 5.5936 19.8951 5.78078 19.7077L21.1958 4.29266C21.3833 4.10527 21.6376 4 21.9027 4C22.1678 4 22.422 4.10527 22.6095 4.29266L28.1958 9.87516C28.3832 10.0627 28.4884 10.3169 28.4884 10.582C28.4884 10.8471 28.3832 11.1014 28.1958 11.2889L12.7808 26.7077C12.5934 26.8948 12.3394 27 12.0745 27.0002Z'
								stroke='black'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
							<path
								d='M17.4883 8L24.4883 15'
								stroke='black'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
						</svg>
					</button>
					<button onClick={() => handleRemove(item)} className='ml-[1rem]'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 16 16'
							fill='none'>
							<path
								d='M13.5 3.5H2.5'
								stroke='#C8001B'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
							<path
								d='M5.5 1.5H10.5'
								stroke='#C8001B'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
							<path
								d='M12.5 3.5V13C12.5 13.1326 12.4473 13.2598 12.3536 13.3536C12.2598 13.4473 12.1326 13.5 12 13.5H4C3.86739 13.5 3.74021 13.4473 3.64645 13.3536C3.55268 13.2598 3.5 13.1326 3.5 13V3.5'
								stroke='#C8001B'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
						</svg>
					</button>
				</div>
			),
		},
	];

	useEffect(() => {}, [natureOfBusiness]);

	useEffect(() => {
		handleFetchNatureOfBusiness();
	}, []);

	return (
		<>
			<div className='w-full mt-[.5rem]'>
				<Table
					pagination={false}
					columns={columns}
					dataSource={natureOfBusiness}
				/>
			</div>
			<NatureOfBusinessModal
				editObj={editObj}
				open={open}
				handleCancel={handleCancel}
			/>
		</>
	);
}
