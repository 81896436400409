import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Select, Spin, message } from 'antd';
import {
	busimessClassData,
	companyCategoryData,
	companyTypeData,
	smallScaleTypeData,
} from '../../../../data';
import TextArea from 'antd/es/input/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import { save, saveFile } from '../../../../features/save/saveSlice';
import { CloudUploadOutlined } from '@ant-design/icons';
import { customToast } from '../../../../utils';
import { fetchId } from '../../../../features/fetch_id/fetchIdSlice';
import { handleDelete } from '../../../../features/delete/deleteSlice';
import { fetchBusinessApplicationDropDowns, fetchBusinessMiningRights, fetchOperationScale } from '../../../../features/fetch/fetchSlice';

export default function ApplicationDetailsStep({ handleNext }) {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const formRef = useRef(null);

	const { saving } = useSelector((state) => state.save);
	const { fetchObj } = useSelector((state) => state.fetchId);
	const { applicationDropDowns, miningRights, operationScale } = useSelector((state) => state.fetch);

	const [formData, setFormData] = useState({
		...fetchObj,
	});
	const [formNames, setFormNames] = useState(
		fetchObj?.apfProposedNames ?? [
			{
				id: '',
				prpName: '',
				prpCfId: '',
				prpStatus: null,
			},
			{
				id: '',
				prpName: '',
				prpCfId: '',
				prpStatus: null,
			},
			{
				id: '',
				prpName: '',
				prpCfId: '',
				prpStatus: null,
			},
		]
	);

	const [businessCategory, setbusinessCategory] = useState([]);
	const [files, setfiles] = useState(fetchObj?.apfAttachments ?? []);

	const businessClass =
		businessCategory && businessCategory[2]?.businessClasses;

	const onChange = async (e) => {
		setFormData((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};

	function handleSelectChange(value, formName) {
		if (formName === 'apfMrId') {
			let filterArray = applicationDropDowns?.filter(
				(item) =>
					String(item.btId).toUpperCase() === String(value).toUpperCase()
			);
			setbusinessCategory(filterArray && filterArray[0]?.businessCategories);
		}
		setFormData((prevData) => ({
			...prevData,
			[formName]: value,
		}));
	}

	async function handleFetchDropDowns() {
		await dispatch(fetchBusinessApplicationDropDowns());
		await dispatch(fetchBusinessMiningRights())
		await dispatch(fetchOperationScale())
	}

	async function handleFileUpload(e) {
		let file = e.target.files[0];

		const formObject = new FormData();
		formObject.append('file', file);

		const res = await dispatch(saveFile(formObject));

		if (res?.payload?.success) {
			let obj = {
				attFile: res?.payload?.data?.result,
				attType: 'APPLICATION',
				attRefId: '',
			};
			setfiles([...files, obj]);
		} else {
			message.error(
				res?.payload?.messages?.error ??
					'There was an error uploading this file'
			);
		}
	}

	async function handleRemoveFile(file) {
		const deleteObj = {
			url: `/v1/api/att/${file?.attId}`,
		};
		await dispatch(handleDelete(deleteObj));
		if (fetchObj?.id) {
			await handleFetchObj(fetchObj?.id);
		}

		let filterArray = files.filter((item) => item?.attFile !== file?.attFile);

		setfiles(filterArray);
	}

	async function handleFetchObj(id) {
		const responseObj = {
			url: `/v1/api/app/${id}`,
		};
		await dispatch(fetchId(responseObj));
	}

	const onFinish = async () => {

		try {
			await form.validateFields();
			const obj = {
				...formData,
				apfApplicationType: 'Application for Mining Rights',
				apfApplicationStatus: 'DRAFT',
				apfUpdatedDate: new Date(),
				url: '/v1/api/app',
			};

			const res = await dispatch(save(obj));

			if (res?.payload?.success) {
				await handleFetchObj(res?.payload?.data?.result?.id);
				handleNext();
			} else {
				message.error(res?.payload?.messages?.message);
			}
		} catch (e) {
			form.submit();
		}
	};

	useEffect(() => {}, [formData]);

	useEffect(() => {
		handleFetchDropDowns();
	}, []);

	useEffect(() => {
		setFormData(fetchObj);
		if (fetchObj?.apfProposedNames?.length === 3) {
			setFormNames(fetchObj?.apfProposedNames);
		}
		if (fetchObj?.apfAttachments) {
			setfiles(fetchObj?.apfAttachments);
		}
	}, [fetchObj]);

	useEffect(() => {
		form.setFieldsValue(fetchObj);
	}, [fetchObj, form]);

	return (
		<>
			<div className='mt-[35px] w-full'>
				<div className='mt-[40px] h-full mb-10'>
					{formData?.apfOsId === '562ef61c-f94b-4ef7-8305-47f61ba2df58' &&
					formData?.apfMrId === 'f71ab677-74d0-4e9d-849d-6c524a442fdd' ? (
						<div className='text-[red] mb-[1rem]'>
							Please note that this type of application requires you to enlist a
							registered business as a partner.
						</div>
					) : formData?.apfMrId === '89e460a1-b58e-45fc-8050-60f045d1df2e' ? (
						<div className='text-[red] mb-[1rem]'>
							Please note that a certificate of incorporation and a letter of
							authorisation from the country of origin, indicating the company's
							intention to register the entity in Zambia, must be attached.
						</div>
					) : null}
					<Form
						layout='vertical'
						ref={formRef}
						name='control-ref'
						scrollToFirstError
						style={{
							maxWidth: '100%',
							marginTop: '2.5rem',
						}}
						initialValues={{
							...formData,
							apfMrId: formData?.apfMrId,
							apfOsId: formData?.apfOsId,
							apfMcId: formData?.apfMcId,
							apfOmitLimited: formData?.apfOmitLimited,
						}}
						form={form}>
						<div className='grid grid-cols-2 gap-x-[24px] w-full'>
							<Form.Item
								name={'apfMrId'}
								label='Type of Mining Rights'
								extra={'Choose option'}
								rules={[
									{
										required: true,
										message: 'Field is required',
									},
								]}>
								<Select
									value={formData?.apfMrId}
									initialValue={formData?.apfMrId}
									className='activity-select-tag activity-select-icon'
									allowClear
									style={{
										width: '100%',
										height: '3.25rem',
									}}
									onChange={(value) => {
										setbusinessCategory([]);
										handleSelectChange(value, 'apfMrId');

										form.setFieldsValue({
											apfOsId: '',
											apfMcId: '',
											apfOmitLimited: '',
										});

										setFormData((prevData) => ({
											...prevData,
											apfBcId: '',
											apfMcId: '',
											apfOmitLimited: '',
										}));
									}}
									options={miningRights?.map((item) => ({
										value: item?.mrId,
										label: item?.mrType,
									}))}
								/>
							</Form.Item>

							<Form.Item
								name={'apfOsId'}
								label='Scale of Mining'
								extra={'Choose option'}
								rules={[
									{
										required: true,
										message: 'Field is required',
									},
								]}>
								<Select
									value={formData?.apfOsId}
									initialValue={formData?.apfOsId}
									className='activity-select-tag activity-select-icon'
									allowClear
									style={{
										width: '100%',
										height: '3.25rem',
									}}
									onChange={(value) => {
										handleSelectChange(value, 'apfOsId');
									}}
									options={operationScale?.map((item) => ({
										value: item?.osId,
										label: item?.osType,
									}))}
								/>
							</Form.Item>
						</div>

						{formData?.apfOsId === 'e716d272-b370-4fb5-be03-4f154bcc10f3' ? (
							<>
								<Form.Item
									name='apfSmallScaleDesc'
									label='Type of Scale '
									extra={'Choose option'}
									rules={[
										{
											required: true,
											message: 'Field is required',
										},
									]}>
									<Select
										value={formData?.apfSmallScaleDesc}
										initialValue={formData?.apfSmallScaleDesc}
										className='activity-select-tag activity-select-icon'
										allowClear
										style={{
											width: '100%',
											height: '3.25rem',
										}}
										onChange={(value) => {
											handleSelectChange(value, 'apfSmallScaleDesc');
										}}
										options={smallScaleTypeData?.map((item) => ({
											value: item?.value,
											label: item?.label,
										}))}
									/>
								</Form.Item>
							</>
						) : null}

						<Form.Item
							name='apfMcId'
							label='Type of Mineral'
							extra={'Choose option'}
							rules={[
								{
									required: true,
									message: 'Field is required',
								},
							]}>
							<Select
								value={formData?.apfMcId}
								initialValue={formData?.apfMcId}
								className='activity-select-tag activity-select-icon'
								allowClear
								style={{
									width: '100%',
									height: '3.25rem',
								}}
								onChange={(value) => {
									handleSelectChange(value, 'apfMcId');
								}}
								options={applicationDropDowns?.map((item) => ({
									value: item?.mcId,
									label: item?.mcType,
								}))}
							/>
						</Form.Item>

						<div className='mt-[52px] flex items-center justify-center flex-col gap-y-[4px] mb-[40px]'>
							<div className='w-[184px]'>
								<button
									disabled={saving}
									type='button'
									onClick={() => onFinish()}
									className='cstm-btn'>
									{saving ? <Spin /> : 'Next'}
								</button>
							</div>
						</div>
					</Form>
				</div>
			</div>
		</>
	);
}
