import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderSummary } from '../../../features/fetch/fetchSlice';
import { useEffect } from 'react';

export default function NameClearanceOrderSummary() {
	const { billObj } = useSelector((state) => state.fetchId);
	const { fetchObj } = useSelector((state) => state.fetchId);

	useEffect(() => {}, [billObj]);

	return (
		<>
			{Object.keys(billObj || {}).length ? (
				<>
					<div className='flex flex-col'>
						<span className='heading_4 !text-blk3 mt-[2.5rem]'>
							Payment summary
						</span>

						<span className='label_1 mt-[2rem]'>{billObj?.pymName}</span>
						<span className='label_2 mt-[1rem]'>{fetchObj?.apfRefId}</span>

						<div className='flex flex-col'>
							<div className='flex items-center mt-[.625rem] py-[1.06rem] px-[.5rem] border-b border-[#BDBDBD]'>
								<span className='paragraph_1 !text-blk3 w-[9.6rem]'>
									Service Fee
								</span>
								<span className='paragraph_1 !text-blk3 ml-[7.25rem]'>
									{billObj?.bill?.bilCost}
								</span>
							</div>

							<div className='flex items-center py-[1.06rem] px-[.5rem] border-b border-[#BDBDBD]'>
								<span className='paragraph_1 !text-blk3 w-[9.6rem]'>
									Transaction Fee
								</span>
								<span className='paragraph_1 !text-blk3 ml-[7.25rem]'>
									ZMW {billObj?.bill?.bilTransactionFee}
								</span>
							</div>

							<div className='flex items-center py-[1.06rem] px-[.5rem] bg-[#F3F3F3]'>
								<span className='heading_4 !text-blk3 w-[9.6rem]'>
									Total
								</span>
								<span className='heading_4 !text-blk3 ml-[7.25rem]'>
									ZMW {billObj?.bill?.bilTotal}
								</span>
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					<div className='flex flex-col'>
						<span className='heading_4 !text-blk3 mt-[2.5rem]'>
							Payment summary
						</span>

						<span className='label_1 mt-[.75rem]'>
							No payment method selected
						</span>
					</div>
				</>
			)}
		</>
	);
}
