import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	applicantDetails: {},
	personDetails: {},
	businessDetails: {},
};

export const storeSlice = createSlice({
	name: 'store',
	initialState,
	reducers: {
		setApplicantDetails: (state, action) => {
			state.applicantDetails = { ...state.applicantDetails, ...action.payload };
		},
		setPersonDetails: (state, action) => {
			state.personDetails = { ...state.personDetails, ...action.payload };
		},
		setBusinessDetails: (state, action) => {
			state.businessDetails = { ...state.businessDetails, ...action.payload };
		},
	},
});

export default storeSlice.reducer;
export const { setApplicantDetails, setPersonDetails, setBusinessDetails } =
	storeSlice.actions;
