import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const homeUrl = 'https://homebe.ndovupay.org'

const initialState = {
	fetching: false,
	categories: [],
	natureOfBusiness: [],
	nameClearanceApplications: [],
	orderSummary: [],
	clearedNames: [],
	applicationDropDowns: [],
	miningRights: [],
	operationScale: [],
};

export const fetchCategory = createAsyncThunk('fetchSlice/fetch', async () => {
	const res = await axiosInstance
		.get(`${url}/v1/api/category`)
		.then((res) => res.data.data.result);
	return res;
});

export const fetchNatureOfBusiness = createAsyncThunk(
	'fetchSlice/fetchNatureOfBusiness',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/v1/api/loc/app/${id}`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchNameClearanceApplications = createAsyncThunk(
	'fetchSlice/fetchNameClearanceApplications',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/v1/api/app`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchOrderSummary = createAsyncThunk(
	'fetchSlice/fetchOrderSummary',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/v1/api/pym/all/${id}`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchClearedNames = createAsyncThunk(
	'fetchSlice/fetchClearedNames',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/v1/api/app/reserve`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchBusinessApplicationDropDowns = createAsyncThunk(
	'fetchSlice/fetchBusinessApplicationDropDowns',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/v1/api/mc`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchBusinessMiningRights = createAsyncThunk(
	'fetchSlice/fetchBusinessMiningRights',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/v1/api/mr`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchOperationScale = createAsyncThunk(
	'fetchSlice/fetchOperationScale',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/v1/api/os`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const stkPush = createAsyncThunk('fetchSlice/stkPush', async (obj) => {
	const res = await axiosInstance
		.get(`${homeUrl}/v1/api/sdk?phone=${obj?.phone}&amount=${obj?.amount}&accountref=${obj?.accountref}`)
		.then((res) => res.data);
	return res;
});


export const fetchSlice = createSlice({
	name: 'fetch',
	initialState,
	reducers: {
		clearNatureOfBusiness: (state) => {
			state.natureOfBusiness = [];
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(fetchOperationScale.pending, (state) => {
				state.fetching = true;
			})
			.addCase(fetchOperationScale.fulfilled, (state, action) => {
				state.fetching = false;
				state.operationScale = action.payload;
			})
			.addCase(fetchOperationScale.rejected, (state) => {
				state.fetching = false;
			})

			.addCase(fetchBusinessMiningRights.pending, (state) => {
				state.fetching = true;
			})
			.addCase(fetchBusinessMiningRights.fulfilled, (state, action) => {
				state.fetching = false;
				state.miningRights = action.payload;
			})
			.addCase(fetchBusinessMiningRights.rejected, (state) => {
				state.fetching = false;
			})

			.addCase(fetchBusinessApplicationDropDowns.pending, (state) => {
				state.fetching = true;
			})
			.addCase(fetchBusinessApplicationDropDowns.fulfilled, (state, action) => {
				state.fetching = false;
				state.applicationDropDowns = action.payload;
			})
			.addCase(fetchBusinessApplicationDropDowns.rejected, (state) => {
				state.fetching = false;
			})

			.addCase(fetchClearedNames.pending, (state) => {
				state.fetching = true;
			})
			.addCase(fetchClearedNames.fulfilled, (state, action) => {
				state.fetching = false;
				state.clearedNames = action.payload;
			})
			.addCase(fetchClearedNames.rejected, (state) => {
				state.fetching = false;
			})

			.addCase(fetchOrderSummary.pending, (state) => {
				state.fetching = true;
			})
			.addCase(fetchOrderSummary.fulfilled, (state, action) => {
				state.fetching = false;
				state.orderSummary = action.payload;
			})
			.addCase(fetchOrderSummary.rejected, (state) => {
				state.fetching = false;
			})

			.addCase(fetchNameClearanceApplications.pending, (state) => {
				state.fetching = true;
			})
			.addCase(fetchNameClearanceApplications.fulfilled, (state, action) => {
				state.fetching = false;
				state.nameClearanceApplications = action.payload;
			})
			.addCase(fetchNameClearanceApplications.rejected, (state) => {
				state.fetching = false;
			})

			.addCase(fetchNatureOfBusiness.pending, (state) => {
				state.fetching = true;
			})
			.addCase(fetchNatureOfBusiness.fulfilled, (state, action) => {
				state.fetching = false;
				state.natureOfBusiness = action.payload;
			})
			.addCase(fetchNatureOfBusiness.rejected, (state) => {
				state.fetching = false;
			})

			.addCase(fetchCategory.pending, (state) => {
				state.fetching = true;
			})
			.addCase(fetchCategory.fulfilled, (state, action) => {
				state.fetching = false;
				state.categories = action.payload;
			})
			.addCase(fetchCategory.rejected, (state) => {
				state.fetching = false;
			});
	},
});

export default fetchSlice.reducer;
export const { clearNatureOfBusiness } = fetchSlice.actions;
