import { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import { stkPush } from '../../../features/fetch/fetchSlice';

export default function MobilePay() {
	const dispatch = useDispatch();

	const { fetching } = useSelector((state) => state.fetch);
	const { invoiceObj } = useSelector((state) => state.fetchId);

	const [phoneNumber, setphoneNumber] = useState('');

	const handleMobileChange = (value) => {
		setphoneNumber(value);
	};

	async function handleStk() {
		let stkObj = {
			phone: phoneNumber,
			// amount: invoiceObj?.invoAmount,
			amount: 1,
			accountref: invoiceObj?.invoCode,
		};
		await dispatch(stkPush(stkObj));
	}

	return (
		<>
			<div className='bank_payment_card min-w-[22rem] p-[2.5rem] mt-[3.25rem]'>
				<span className='heading_4 !text-blk'>Payment: ZMW {invoiceObj?.invoAmount}</span>
				{/* <span className='label_2 !text-[#3482FF] mt-[1.5rem]'>
					Download Payment Instructions
				</span> */}

				<div className='mt-[1.69rem] max-w-[22rem] w-full'>
					<label className='label_2 !text-blk21'>Phone number</label>
					<PhoneInput
						country='zm'
						// onlyCountries={['zm']}
						value={phoneNumber}
						onChange={handleMobileChange}
						countryCodeEditable={false}
						className='input'
					/>
				</div>

				<div className='w-full flex justify-center items-center'>
					<button
						onClick={handleStk}
						disabled={phoneNumber.length < 9}
						className='green-border-btn mt-[1.5rem] !w-[14rem]'>
						{fetching ? "Processing..." : 'Send STK'}
					</button>
				</div>
			</div>
		</>
	);
}
