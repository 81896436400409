import { useNavigate } from 'react-router-dom';
import ProfileHeader from './ProfileHeader';
import ProfileSummary from './ProfileSummary';
import { Switch, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { save } from '../../features/save/saveSlice';

export default function AccountSettings() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);

	async function handleGetPhoneOpt() {
		await navigate('/account-settings/verify-phone');
		// const resp = await dispatch(
		// 	save({
		// 		usrId: user?.usrId,
		// 		usrSecret: user?.family_name,
		// 		url: 'api/v1/usr/validate-phone-otp',
		// 	})
		// );
		// if (resp?.payload?.success) {
		// 	await message.success(resp?.payload?.messages?.message);
		// 	await navigate('/account-settings/verify-email');
		// } else {
		// 	message.error(resp?.payload?.messages?.message);
		// }
	}

	async function handleGetEmailOpt() {
		await navigate('/account-settings/verify-email');
		// const res = await dispatch(
		// 	save({
		// 		usrId: user?.usrId,
		// 		usrPhone: user?.email,
		// 		url: 'api/v1/usr/validate-email-otp',
		// 	})
		// );

		// if (res?.payload?.success) {
		// 	await message.success(res?.payload?.messages?.message);
		// 	await navigate('/account-settings/verify-email');
		// } else {
		// 	message.error(res?.payload?.messages?.message);;
		// }
	}

	async function handleUpdatePassword() {
		await navigate('/account-settings/current-password');
	}

	return (
		<>
			<div className='w-full bg-white min-h-auto pb-[10rem]'>
				<ProfileHeader />
				<div className='mt-[1.12rem]'>
					<ProfileSummary />
				</div>

				<div className='mt-[4.37rem] pl-[6.44rem] pr-[4.38rem] flex items-center justify-center w-full'>
					<div className='flex flex-col min-w-[37.875rem]'>
						<span className='heading_3'>Account settings</span>

						<div className='mt-[2.31rem] account_setting_card'>
							<span className='heading_4 !text-[#6B7280]'>
								Account access details
							</span>

							<div className='mt-[2.31rem] flex flex-col gap-y-[2rem]'>
								<div className='flex items-center justify-between w-full'>
									<div className='flex flex-col justify-start items-start'>
										<span className='label_2 !text-[#6B7280]'>
											Phone number
										</span>
										<span className='paragraph_1 !text-blk21 mt-[.75rem]'>
											+{user?.family_name}
										</span>
									</div>

									<button
										onClick={handleGetPhoneOpt}
										className='label_2 !text-[#3482FF] mr-[2.5rem]'>
										Change
									</button>
								</div>

								<div className='flex items-center justify-between w-full'>
									<div className='flex flex-col justify-start items-start'>
										<span className='label_2 !text-[#6B7280]'>Email</span>
										<span className='paragraph_1 !text-blk21 mt-[.75rem]'>
											{user?.email}
										</span>
									</div>
									<button
										onClick={handleGetEmailOpt}
										className='label_2 !text-[#3482FF] mr-[2.5rem]'>
										Change
									</button>
								</div>

								<div className='flex items-center justify-between w-full'>
									<div className='flex flex-col justify-start items-start'>
										<span className='label_2 !text-[#6B7280]'>Password</span>
										<span className='paragraph_1 !text-blk21 mt-[.75rem]'>
											**************
										</span>
									</div>
									<button
										onClick={handleUpdatePassword}
										className='label_2 !text-[#3482FF] mr-[2.5rem]'>
										Change
									</button>
								</div>
							</div>
						</div>

						<div className='mt-[2.31rem] account_setting_card'>
							<span className='heading_4 !text-[#6B7280]'>
								Two factor authentication (2FA)
							</span>

							<div className='mt-[2.31rem] flex flex-col gap-y-[2rem]'>
								<div className='flex items-center gap-x-[1rem] w-full'>
									<Switch defaultChecked />
									<div className='flex flex-col justify-start items-start'>
										<span className='label_2 !text-[#6B7280]'>
											One Time Password (OTP)
										</span>
										<span className='paragraph_1 !text-blk21 mt-[.75rem]'>
											OTP sent to your email or phone
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
