import { Tabs } from 'antd';
import ApplicationNameGuidelinesTab from './help_tabs/ApplicationNameGuidelinesTab';
import ApplicationNameGuideFeesTab from './help_tabs/ApplicationNameGuideFeesTab';
import HelpStep from '../../../components/HelpStep';

export default function NameClearanceHelp() {
	const tabItems = [
		// {
		// 	label: 'Guidelines',
		// 	key: '1',
		// 	children: <ApplicationNameGuidelinesTab />,
		// },
		// {
		// 	label: 'Fees',
		// 	key: '2',
		// 	children: <ApplicationNameGuideFeesTab />,
		// },

		{
			label: 'Help',
			key: '3',
			children: <HelpStep />,
		},
	];

	return (
		<>
			<Tabs className='underlined_tab' defaultActiveKey='3' items={tabItems} />
		</>
	);
}
