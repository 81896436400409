import { useSelector } from 'react-redux';

export default function DetailsTab() {
	const { viewObj } = useSelector((state) => state.fetchId);

	return (
		<>
			<div className='mt-[.94rem] bg-white px-[2.5rem] w-full'>
				<div className='mt-[3.75rem] border border-[#EAECF0]'>
					<div className='application_details_header'>
						<span className='label_2'>Application Details</span>
					</div>

					<div className='flex items-center application_details_body'>
						<span className='label_3 w-[14.125rem] flex shrink-0'>Service</span>
						<span className='paragraph_2 w-full'>
							{viewObj?.apfApplicationType}
						</span>
					</div>
					<div className='flex items-center application_details_body'>
						<span className='label_3 w-[14.125rem] flex shrink-0'>
							Application ID
						</span>
						<span className='paragraph_2 w-full'>{viewObj?.apfRefId}</span>
					</div>

					<div className='flex items-center application_details_body'>
						<span className='label_3 w-[14.125rem] flex shrink-0'>
							Type of Mining Right
						</span>
						<span className='paragraph_2 w-full'>
							{viewObj?.apfMiningRight}
						</span>
					</div>

					<div className='flex items-center application_details_body'>
						<span className='label_3 w-[14.125rem] flex shrink-0'>
							Scale of Operation
						</span>
						<span className='paragraph_2 w-full'>
							{viewObj?.apfOperationScale ?? 'N/A'}
						</span>
					</div>

					<div className='flex items-center application_details_body'>
						<span className='label_3 w-[14.125rem] flex shrink-0'>
							Type of Mineral
						</span>
						<span className='paragraph_2 w-full'>
							{viewObj?.apfMineralCategory}
						</span>
					</div>
				</div>
			</div>
		</>
	);
}
