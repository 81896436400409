import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	deleting: false,
};

export const handleDelete = createAsyncThunk(
	'deleteSlice/delete',
	async (data) => {
		let deleteUrl = data.url;
		delete data.url;
		const res = await axiosInstance
			.delete(`${url}${deleteUrl}`)
			.then((res) => res.data);
		return res;
	}
);

export const deleteSlice = createSlice({
	name: 'delete',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(handleDelete.pending, (state) => {
				state.deleting = true;
			})
			.addCase(handleDelete.fulfilled, (state, action) => {
				state.deleting = false;
			})
			.addCase(handleDelete.rejected, (state) => {
				state.deleting = false;
			});
	},
});

export default deleteSlice.reducer;
export const {} = deleteSlice.actions;
