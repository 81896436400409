import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	fetching: false,
	fetchObj: {},
	viewObj: {},
	billObj: {},
	invoiceObj: {},
	receiptObj: {},
};

export const fetchId = createAsyncThunk('fetchIdSlice/fetch', async (data) => {
	let fetchUrl = data.url;
	delete data.url;
	const res = await axiosInstance
		.get(`${url}${fetchUrl}`)
		.then((res) => res.data.data.result);
	return res;
});

export const fetchInvoice = createAsyncThunk(
	'fetchIdSlice/fetchInvoice',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/v1/api/invoice/${id}`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const cancelInvoice = createAsyncThunk(
	'fetchIdSlice/cancelInvoice',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/v1/api/invoice/cancel/${id}`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchReceipt = createAsyncThunk(
	'fetchIdSlice/fetchReceipt',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/v1/api/receipt/${id}`)
			.then((res) => res.data);
		return res;
	}
);

export const fetchIdSlice = createSlice({
	name: 'fetchId',
	initialState,
	reducers: {
		clearFetchObject: (state) => {
			state.fetchObj = {};
		},
		setViewObj: (state, action) => {
			state.viewObj = action.payload;
		},
		setBillObj: (state, action) => {
			state.billObj = action.payload;
		},
		clearBillObj: (state) => {
			state.billObj = {};
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(fetchReceipt.pending, (state) => {
				state.fetching = true;
			})
			.addCase(fetchReceipt.fulfilled, (state, action) => {
				state.fetching = false;
				state.receiptObj = action?.payload?.data?.result;
			})
			.addCase(fetchReceipt.rejected, (state) => {
				state.fetching = false;
			})

			.addCase(cancelInvoice.pending, (state) => {
				state.fetching = true;
			})
			.addCase(cancelInvoice.fulfilled, (state, action) => {
				state.fetching = false;
				state.billObj = {};
			})
			.addCase(cancelInvoice.rejected, (state) => {
				state.fetching = false;
			})

			.addCase(fetchInvoice.pending, (state) => {
				state.fetching = true;
			})
			.addCase(fetchInvoice.fulfilled, (state, action) => {
				state.fetching = false;
				state.invoiceObj = action.payload;
			})
			.addCase(fetchInvoice.rejected, (state) => {
				state.fetching = false;
			})

			.addCase(fetchId.pending, (state) => {
				state.fetching = true;
			})
			.addCase(fetchId.fulfilled, (state, action) => {
				state.fetching = false;
				state.fetchObj = action.payload;
			})
			.addCase(fetchId.rejected, (state) => {
				state.fetching = false;
			});
	},
});

export default fetchIdSlice.reducer;
export const { clearFetchObject, setViewObj, setBillObj, clearBillObj } =
	fetchIdSlice.actions;
