import React, { useEffect, useState } from 'react';
import image3 from '../../../assets/img/image3.png';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderSummary } from '../../../features/fetch/fetchSlice';
import {
	clearBillObj,
	fetchId,
	setBillObj,
} from '../../../features/fetch_id/fetchIdSlice';
import { save } from '../../../features/save/saveSlice';
import { Spin, message } from 'antd';

// const items = [
// 	{
// 		key: 1,
// 		title: 'Card',
// 		icon: (
// 			<svg
// 				xmlns='http://www.w3.org/2000/svg'
// 				width='16'
// 				height='16'
// 				viewBox='0 0 16 16'
// 				fill='none'>
// 				<path
// 					fill-rule='evenodd'
// 					clip-rule='evenodd'
// 					d='M16 4H0V3.25C0 2.56 0.448 2 1 2H15C15.552 2 16 2.56 16 3.25V4ZM16 6.5V13C16 13.2652 15.8946 13.5196 15.7071 13.7071C15.5196 13.8946 15.2652 14 15 14H1C0.734784 14 0.48043 13.8946 0.292893 13.7071C0.105357 13.5196 0 13.2652 0 13V6.5H16ZM4 10C3.73478 10 3.48043 10.1054 3.29289 10.2929C3.10536 10.4804 3 10.7348 3 11C3 11.2652 3.10536 11.5196 3.29289 11.7071C3.48043 11.8946 3.73478 12 4 12H5C5.26522 12 5.51957 11.8946 5.70711 11.7071C5.89464 11.5196 6 11.2652 6 11C6 10.7348 5.89464 10.4804 5.70711 10.2929C5.51957 10.1054 5.26522 10 5 10H4Z'
// 					fill='#727F96'
// 				/>
// 			</svg>
// 		),
// 	},
// 	{
// 		key: 2,
// 		title: 'Bank',
// 		icon: (
// 			<svg
// 				xmlns='http://www.w3.org/2000/svg'
// 				width='24'
// 				height='24'
// 				viewBox='0 0 24 24'
// 				fill='none'>
// 				<path
// 					d='M2.25 9.75002H4.5V15.75H3C2.80109 15.75 2.61032 15.829 2.46967 15.9697C2.32902 16.1103 2.25 16.3011 2.25 16.5C2.25 16.6989 2.32902 16.8897 2.46967 17.0304C2.61032 17.171 2.80109 17.25 3 17.25H21C21.1989 17.25 21.3897 17.171 21.5303 17.0304C21.671 16.8897 21.75 16.6989 21.75 16.5C21.75 16.3011 21.671 16.1103 21.5303 15.9697C21.3897 15.829 21.1989 15.75 21 15.75H19.5V9.75002H21.75C21.9132 9.74986 22.0719 9.69647 22.202 9.59797C22.3321 9.49947 22.4265 9.36122 22.4709 9.2042C22.5153 9.04717 22.5073 8.87995 22.4481 8.72788C22.3889 8.57582 22.2817 8.44722 22.1428 8.36158L12.3928 2.36158C12.2747 2.28894 12.1387 2.25049 12 2.25049C11.8613 2.25049 11.7253 2.28894 11.6072 2.36158L1.85719 8.36158C1.71828 8.44722 1.61108 8.57582 1.55187 8.72788C1.49266 8.87995 1.48466 9.04717 1.52908 9.2042C1.57351 9.36122 1.66793 9.49947 1.79803 9.59797C1.92814 9.69647 2.08681 9.74986 2.25 9.75002ZM6 9.75002H9V15.75H6V9.75002ZM13.5 9.75002V15.75H10.5V9.75002H13.5ZM18 15.75H15V9.75002H18V15.75ZM12 3.88033L19.1006 8.25002H4.89937L12 3.88033ZM23.25 19.5C23.25 19.6989 23.171 19.8897 23.0303 20.0304C22.8897 20.171 22.6989 20.25 22.5 20.25H1.5C1.30109 20.25 1.11032 20.171 0.96967 20.0304C0.829018 19.8897 0.75 19.6989 0.75 19.5C0.75 19.3011 0.829018 19.1103 0.96967 18.9697C1.11032 18.829 1.30109 18.75 1.5 18.75H22.5C22.6989 18.75 22.8897 18.829 23.0303 18.9697C23.171 19.1103 23.25 19.3011 23.25 19.5Z'
// 					fill='#727F96'
// 				/>
// 			</svg>
// 		),
// 	},
// 	{
// 		key: 3,
// 		title: 'Airtel Money',
// 		icon: (
// 			<img
// 				className='w-[1.5rem] h-[1.5rem] object-contain'
// 				src={image3}
// 				alt='Airtel money'
// 			/>
// 		),
// 	},
// ];

const card = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M16 4H0V3.25C0 2.56 0.448 2 1 2H15C15.552 2 16 2.56 16 3.25V4ZM16 6.5V13C16 13.2652 15.8946 13.5196 15.7071 13.7071C15.5196 13.8946 15.2652 14 15 14H1C0.734784 14 0.48043 13.8946 0.292893 13.7071C0.105357 13.5196 0 13.2652 0 13V6.5H16ZM4 10C3.73478 10 3.48043 10.1054 3.29289 10.2929C3.10536 10.4804 3 10.7348 3 11C3 11.2652 3.10536 11.5196 3.29289 11.7071C3.48043 11.8946 3.73478 12 4 12H5C5.26522 12 5.51957 11.8946 5.70711 11.7071C5.89464 11.5196 6 11.2652 6 11C6 10.7348 5.89464 10.4804 5.70711 10.2929C5.51957 10.1054 5.26522 10 5 10H4Z'
			fill='#727F96'
		/>
	</svg>
);

let mobile = (
	<img
		className='w-[1.5rem] h-[1.5rem] object-contain'
		src={image3}
		alt='Airtel money'
	/>
);

let bank = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'>
		<path
			d='M2.25 9.75002H4.5V15.75H3C2.80109 15.75 2.61032 15.829 2.46967 15.9697C2.32902 16.1103 2.25 16.3011 2.25 16.5C2.25 16.6989 2.32902 16.8897 2.46967 17.0304C2.61032 17.171 2.80109 17.25 3 17.25H21C21.1989 17.25 21.3897 17.171 21.5303 17.0304C21.671 16.8897 21.75 16.6989 21.75 16.5C21.75 16.3011 21.671 16.1103 21.5303 15.9697C21.3897 15.829 21.1989 15.75 21 15.75H19.5V9.75002H21.75C21.9132 9.74986 22.0719 9.69647 22.202 9.59797C22.3321 9.49947 22.4265 9.36122 22.4709 9.2042C22.5153 9.04717 22.5073 8.87995 22.4481 8.72788C22.3889 8.57582 22.2817 8.44722 22.1428 8.36158L12.3928 2.36158C12.2747 2.28894 12.1387 2.25049 12 2.25049C11.8613 2.25049 11.7253 2.28894 11.6072 2.36158L1.85719 8.36158C1.71828 8.44722 1.61108 8.57582 1.55187 8.72788C1.49266 8.87995 1.48466 9.04717 1.52908 9.2042C1.57351 9.36122 1.66793 9.49947 1.79803 9.59797C1.92814 9.69647 2.08681 9.74986 2.25 9.75002ZM6 9.75002H9V15.75H6V9.75002ZM13.5 9.75002V15.75H10.5V9.75002H13.5ZM18 15.75H15V9.75002H18V15.75ZM12 3.88033L19.1006 8.25002H4.89937L12 3.88033ZM23.25 19.5C23.25 19.6989 23.171 19.8897 23.0303 20.0304C22.8897 20.171 22.6989 20.25 22.5 20.25H1.5C1.30109 20.25 1.11032 20.171 0.96967 20.0304C0.829018 19.8897 0.75 19.6989 0.75 19.5C0.75 19.3011 0.829018 19.1103 0.96967 18.9697C1.11032 18.829 1.30109 18.75 1.5 18.75H22.5C22.6989 18.75 22.8897 18.829 23.0303 18.9697C23.171 19.1103 23.25 19.3011 23.25 19.5Z'
			fill='#727F96'
		/>
	</svg>
);

export default function ChoosePaymentStep({ handleBack }) {
	const dispatch = useDispatch();

	const { fetchObj } = useSelector((state) => state.fetchId);
	const { orderSummary } = useSelector((state) => state.fetch);
	const { saving } = useSelector((state) => state.save);

	const [value, setValue] = useState();

	const onChange = (value) => {
		dispatch(setBillObj(value));
		setValue(value?.pymId);
	};

	async function handleInitialFetch() {
		await dispatch(fetchOrderSummary(fetchObj?.apfMrId));
	}

	async function handleFetchObj() {
		const responseObj = {
			url: `/v1/api/app/${fetchObj?.id}`,
		};
		await dispatch(fetchId(responseObj));
	}

	console.log(fetchObj);

	async function handleOnSubmit() {
		const obj = {
			...fetchObj,
			apfApplicationStatus: 'PENDING_PAYMENT',
			apfPymId: value,
			url: '/v1/api/app',
		};

		const res = await dispatch(save(obj));
		if (res?.payload?.success) {
			handleFetchObj();
		} else {
			message.error('An error has occured while submitting your request');
		}
	}

	useEffect(() => {}, [orderSummary]);

	useEffect(() => {
		handleInitialFetch();
		dispatch(clearBillObj());
	}, []);

	return (
		<>
			<div className='mt-[35px] bg-white h-full'>
				<div className='mt-[]'>
					<span className='label_2 '>Choose payment option</span>
				</div>

				<div className='flex items-center gap-x-[.94rem] w-full mt-[.75rem]'>
					{orderSummary?.map((item) => {
						return (
							<>
								<button
									onClick={() => onChange(item)}
									key={item?.pymId}
									className={
										value === item?.pymId
											? 'payment_card_active'
											: `payment_card`
									}>
									<span
										className={
											value === item?.pymId
												? 'payment_card_svg_active'
												: `payment_card_svg`
										}>
										{item?.pymName === 'Bank Payment'
											? card
											: item?.pymName === 'MObile Money'
											? mobile
											: bank}
									</span>
									<span
										className={
											value === item?.pymId
												? 'payment_card_title_active'
												: `payment_card_title`
										}>
										{item.pymName}
									</span>

									<span className='label_2 !text-blk2B mt-[1rem]'>
										ZMW {item?.bill?.bilTotal ?? 0}
									</span>
								</button>
							</>
						);
					})}
				</div>

				<div className='mt-[52px] flex items-center justify-center flex-col gap-y-[4px] mb-[40px]'>
					<div className='w-[184px]'>
						<button
							disabled={!value || saving}
							type='button'
							onClick={handleOnSubmit}
							className='cstm-btn'>
							{saving ? <Spin /> : 'Next'}
						</button>
					</div>

					<div className='w-[184px]'>
						<button
							type='button'
							onClick={() => handleBack()}
							className='cstm-btn-borderless'>
							Previous
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
