import { Breadcrumb, Tabs } from 'antd';
import DetailsTab from './tabs/DetailsTab';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import {
	clearFetchObject,
	fetchId,
} from '../../features/fetch_id/fetchIdSlice';
import StatusTab from './tabs/StatusTab';
import { clearNatureOfBusiness, fetchNatureOfBusiness } from '../../features/fetch/fetchSlice';
import NatureTab from './tabs/NatureTab';

const items = [
	{
		title: <Link to='/services'>Services</Link>,
	},
	{
		title: <Link to='/services/application-history'>Application History</Link>,
	},
	{
		title: 'Application',
	},
];

export default function ViewApplicationHistory() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { viewObj } = useSelector((state) => state.fetchId);

	const view = useRef();

	const tabItems = [
		{
			label: 'Application Status',
			key: '1',
			children: <StatusTab />,
		},
		{
			label: 'Application Details',
			key: '2',
			children: <DetailsTab />,
		},

		{
			label: 'Location Coordinates',
			key: '3',
			children: <NatureTab />,
		},
	];

	async function handleFetchObj() {
		const responseObj = {
			url: `/v1/api/app/${viewObj?.id}`,
		};
		await dispatch(fetchId(responseObj));
	}

	const handleFetchNatureOfBusiness = async () => {
		await dispatch(fetchNatureOfBusiness(viewObj?.id));
	};

	async function handleProceedToPay() {
		const responseObj = {
			url: `/v1/api/app/${viewObj?.id}`,
		};
		await dispatch(fetchId(responseObj));
		await navigate('/services/company-forms/name-clearance/application');
	}

	async function handleNewApplciation() {
		await dispatch(clearNatureOfBusiness());
		await dispatch(clearFetchObject());
		await navigate('/services/company-forms/name-clearance/application');
	}

	useEffect(() => {
		view?.current?.scrollIntoView();
	}, []);

	useEffect(() => {
		handleFetchObj();
		handleFetchNatureOfBusiness()
	}, [viewObj]);

	return (
		<>
			<div
				ref={view}
				className='mt-[.94rem] bg-white py-[2.64rem] px-[2.5rem] w-full'>
				<div className='flex items-center justify-between'>
					<div className='flex flex-col w-full'>
						<Breadcrumb items={items} />
						<span ref={view} className='heading_4 !text-blk21 mt-[1.94rem]'>
							Ref: {viewObj?.apfRefId}
						</span>

						<span className='label_1 !text-blk21 mt-[1.69rem]'>
							{viewObj?.apfApplicationType}
						</span>
					</div>

					{String(viewObj?.apfApplicationStatus).toUpperCase() ===
					'PENDING_PAYMENT' || String(viewObj?.apfApplicationStatus).toUpperCase() === 'PAYMENT_OPTIONS' ? (
						<button onClick={handleProceedToPay} className='cstm-btn-2 w-auto'>
							Proceed to pay
						</button>
					) : String(viewObj?.apfApplicationStatus).toUpperCase() ===
					  'DRAFT' ? (
						<button onClick={handleProceedToPay} className='cstm-btn-2 w-auto'>
							Complete appplication
						</button>
					) : String(viewObj?.apfApplicationStatus).toUpperCase() ===
					  String('REJECTED').toUpperCase() ? (
						<button
							onClick={handleNewApplciation}
							className='cstm-btn-2 w-auto'>
							Start new application
						</button>
					) : null}
				</div>
				<Tabs
					className='underlined_tab mt-[2.5rem]'
					defaultActiveKey='1'
					items={tabItems}
				/>
			</div>
		</>
	);
}
