import { Link } from 'react-router-dom';
import svg6 from '../assets/svg/svg6.svg';
import svg7 from '../assets/svg/svg7.svg';

export default function GridServicesCard({ item, handleActiveService }) {
	return (
		<>
			<button
				onClick={() => handleActiveService(item?.srcLink)}
				className='service-card flex-grow px-[36.75px] pt-[36px] pb-[1rem] mt-[1.25rem]'>
				<div className='label_1 text-left'>{item?.srcName}</div>
				<div className='mt-[16px] service-line'></div>
				<div className='mt-[20px] Label_3 w-full text-left'>
					{item?.srcDescription}
				</div>
				<div className='flex flex-end justify-end cursor-pointer'>
					{/* <svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'>
						<path
							d='M9.00049 4.5L16.5005 12L9.00049 19.5'
							stroke='#212121'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
						/>
					</svg> */}

						<span className={`text-[#222]`}>
							<img src={svg6} alt='svg6' />
						</span>
				</div>
			</button>
		</>
	);
}
