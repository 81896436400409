import { Breadcrumb, Tabs } from 'antd';
import { useRef, useState } from 'react';
import ApplicationDetailsStep from './steps/ApplicationDetailsStep';
import { Link } from 'react-router-dom';
import NameClearanceHelp from './NameClearanceHelp';
import Payment from '../../payment/Payment';
import NatureOfBusinessStep from './steps/NatureOfBusinessStep';
import { useSelector } from 'react-redux';
import NameClearanceOrderSummary from './NameClearanceOrderSummary';
import BlackNav from '../../../components/BlackNav';

const items = [
	{
		title: <Link to='/services'>Services</Link>,
	},
	{
		title: (
			<Link to='/services'>
				Mining Rights
			</Link>
		),
	},
	{
		title: 'Application Form',
	},
];

export default function ApplicationOfNameClearance() {
	const { fetchObj } = useSelector((state) => state.fetchId);

	const view = useRef();

	const [current, setcurrent] = useState(
		fetchObj?.apfApplicationStatus === 'PAYMENT_OPTIONS' || fetchObj?.apfApplicationStatus === 'PENDING_PAYMENT' ? '3' : '1'
	);

	const tabItems = [
		{
			label: 'Application Details',
			key: '1',
			children: (
				<ApplicationDetailsStep
					handleBack={handleBack}
					handleNext={handleNext}
				/>
			),
		},
		{
			label: 'Mining Location',
			key: '2',
			children: (
				<NatureOfBusinessStep handleBack={handleBack} handleNext={handleNext} />
			),
		},

		{
			label: 'Payment',
			key: '3',
			children: <Payment handleBack={handleBack} />,
		},
	];

	function handleNext() {
		console.log(current);
		let num = Number(current);
		if (num < 3) {
			num += 1;
			setcurrent(String(num));
			view?.current?.scrollIntoView();
		} else {
			setcurrent(current);
		}
	}

	function handleBack() {
		let num = Number(current);
		if (num > 0) {
			num -= 1;
			setcurrent(String(num));
			view?.current?.scrollIntoView();
		} else {
			setcurrent(current);
		}
	}

	return (
		<>
		<BlackNav text={'Ministry of Mines and Minerals Development'} />
			<div className='flex justify-start items-start mt-[.94rem] w-full'>
				<div className='bg-white py-[2.64rem] px-[2.5rem] h-full w-full'>
					<div className='flex flex-col w-full'>
						<Breadcrumb items={items} />
						<span ref={view} className='heading_4 !text-blk21 mt-[1.94rem]'>
						The Mines and Minerals Development Act, 2015 (Act No. 11 of 2015)

						</span>

						<span className='label_1 !text-blk21 mt-[1.69rem]'>
						The Mines and Minerals Development (General) Regulations, 2016
						</span>

						<div className='p-[2.5rem] border border-[#ECECEC] mt-[2.5rem] w-full'>
							<Tabs
								activeKey={current}
								defaultActiveKey='1'
								items={tabItems}
								// onChange={onChange}
							/>
						</div>
					</div>
				</div>

				<div className='hidden lg:flex lg:min-w-[30.625rem] lg:w-[30.625rem] max-w-full bg-white h-full py-[3.75rem] px-[2.5rem] ml-[.94rem]'>
					{fetchObj?.apfApplicationStatus === 'PAYMENT_OPTIONS' || fetchObj?.apfApplicationStatus ===  'PENDING_PAYMENT' ? (
						<NameClearanceOrderSummary />
					) : (
						<NameClearanceHelp />
					)}
				</div>
			</div>
		</>
	);
}
