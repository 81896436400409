import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChoosePaymentStep from './tabs/ChoosePaymentStep';
import MobilePay from './tabs/MobilePay';
import { useNavigate } from 'react-router-dom';
import { save } from '../../features/save/saveSlice';
import {
	cancelInvoice,
	fetchId,
	fetchInvoice,
	fetchReceipt,
} from '../../features/fetch_id/fetchIdSlice';
import { message } from 'antd';

export default function Payment({ canGoBack, handleBack }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { fetchObj, invoiceObj } = useSelector((state) => state.fetchId);

	async function handleFetchObj() {
		const responseObj = {
			url: `/v1/api/app/${fetchObj?.id}`,
		};
		await dispatch(fetchId(responseObj));
	}

	async function handleFetchInvoice() {
		await dispatch(fetchInvoice(fetchObj?.id));
	}

	async function handleCancelInvoice() {
		const obj = {
			...fetchObj,
			apfApplicationStatus: 'PAYMENT_OPTIONS',
			apfPymId: '',
			url: '/v1/api/app',
		};

		const cancelling = await dispatch(cancelInvoice());

		if (cancelling?.payload?.success) {
			const res = await dispatch(save(obj));
			if (res?.payload?.success) {
				handleFetchObj();
			} else {
				message.error('An error has occured while submitting your request');
			}
		} else {
			message.error('An error has occured. PLease try again later');
		}
	}

	async function handlePayInvoice() {
		const res = await dispatch(fetchReceipt(invoiceObj?.invoCode));
		if (res?.payload?.success) {
			handleFetchObj();
			navigate('/services/application-history');
		} else {
			message.error(
				'We could not verify your payment. Contact support for further help'
			);
		}
	}

	useEffect(() => {}, [fetchObj, invoiceObj]);

	useEffect(() => {
		handleFetchInvoice();
	}, []);

	return (
		<>
			{fetchObj?.apfApplicationStatus === 'PENDING_PAYMENT' ? (
				<>
					<MobilePay />
					{/* {String(invoiceObj?.invoType).toUpperCase().includes('MOBILE') ? (
						<MobilePay />
					) : String(invoiceObj?.invoType)
							.toUpperCase()
							.includes('CARD') ? (
						<CardPay />
					) : (
						<BankPay />
					)} */}

					<div
						style={{
							width: '100%',
							height: '11.125rem',
							display: 'flex',
							padding: '1.25rem',
							alignItems: 'center',
							justifyContent: 'center',
							gap: '1rem',
							flex: '1 0 0',
							background: '#FFF',
						}}
						className='mt-[1.25rem]'>
						<div className='flex flex-col min-w-[14rem] w-fit'>
							<button onClick={handlePayInvoice} className='cstm-btn'>
								Validate payment
							</button>
							{canGoBack ? (
								<button
									onClick={() => handleBack()}
									className='cstm-btn-borderless'>
									Back
								</button>
							) : (
								<button
									onClick={handleCancelInvoice}
									className='cstm-btn-borderless'>
									Cancel
								</button>
							)}
						</div>
					</div>
				</>
			) : (
				<ChoosePaymentStep handleBack={handleBack} />
			)}
		</>
	);
}
