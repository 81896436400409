import { useSelector } from 'react-redux';
import moment from 'moment';

export default function StatusTab() {
	const { viewObj } = useSelector((state) => state.fetchId);

	return (
		<>
			<div className='mt-[.94rem] bg-white px-[2.5rem] w-full'>
				<div className='mt-[3.75rem] border border-[#EAECF0]'>
					<div className='application_details_header'>
						<span className='label_2'>Status</span>
					</div>
					<div className='flex items-center application_details_body'>
						<span className='paragraph_2 min-w-[14.125rem] w-auto flex shrink-0'>
							{viewObj?.apfApplicationStatus === 'PAID'
								? 'PROCESSING'
								: String(viewObj?.apfApplicationStatus).toUpperCase()}
						</span>
					</div>
				</div>

				<div className='mt-[3.75rem] border border-[#EAECF0]'>
					<div className='application_details_header'>
						<span className='label_2'>Update History</span>
					</div>
					<div className='flex items-center application_details_body'>
						<span className='label_3 w-[14.125rem] flex shrink-0'>
							Date Created
						</span>
						<span className='paragraph_2 w-full'>
							{moment(viewObj?.apfCreatedDate ?? new Date()).format('LLL')}
						</span>
					</div>
					<div className='flex items-center application_details_body'>
						<span className='label_3 w-[14.125rem] flex shrink-0'>
							Last Updated
						</span>
						<span className='paragraph_2 w-full'>
							{moment(viewObj?.lastModifiedDate ?? new Date()).format('LLL')}
						</span>
					</div>
				</div>
			</div>
		</>
	);
}
