import { Form, Select, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function ReservationApplicationStep({ handleNext }) {
	const navigate = useNavigate();
	const { saving } = useSelector((state) => state.save);
	const { clearedNames } = useSelector((state) => state.fetch);

	const [selectedName, setselectedName] = useState(
		clearedNames?.length === 0 ? 'No Valid Name Clearance' : ''
	);

	async function onFinish() {
		handleNext();
	}

	useEffect(() => {}, [selectedName, clearedNames]);

	return (
		<>
			<div className='mt-[35px] w-[70%]'>
				<div className='mt-[40px] h-full mb-10'>
					{true ? (
						<div className='text-[red] mb-[1rem]'>
							Please note this service will reserve your cleared name for an additional 90 days.
						</div>
					) : null}

					<div className='mt-[1.5rem]'>
						<label>Cleared Name Reference</label>

						<Select
							disabled={clearedNames?.length === 0}
							value={selectedName}
							className='activity-select-tag activity-select-icon'
							allowClear
							style={{
								width: '100%',
								height: '3.25rem',
							}}
							onChange={(value) => {
								setselectedName(value);
							}}
							options={clearedNames?.map((item) => ({
								value: item?.id,
								label: item?.apfSelectedName,
							}))}
						/>
					</div>

					<div className='mt-[52px] flex items-center justify-center flex-col gap-y-[4px] mb-[40px]'>
						<div className='w-[184px]'>
							<button
								disabled={
									selectedName === 'No Valid Name Clearance' ||
									selectedName?.length === 0 ||
									saving
								}
								type='button'
								onClick={onFinish}
								className='cstm-btn'>
								{saving ? <Spin /> : 'Next'}
							</button>

							<button
								onClick={() => navigate('/services/application-history')}
								className='cstm-btn-borderless'>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
