import { Link } from 'react-router-dom';
import ProfileHeader from './ProfileHeader';
import ProfileSummary from './ProfileSummary';
import { Tabs } from 'antd';
import IdentityDocsTab from './tabs/IdentityDocsTab';
import { useState } from 'react';
import OrganizationsTab from './tabs/OrganizationsTab';

export default function MainProfile() {
	const [active, setactive] = useState(1);

	return (
		<>
			<div className='w-full bg-white min-h-auto pb-[10rem]'>
				<ProfileHeader />
				<div className='mt-[1.12rem]'>
					<ProfileSummary />
				</div>

				<div className='mt-[4.37rem] pl-[6.44rem] pr-[4.38rem]'>
					<div className='flex justify-start items-start w-full pl-[1.81rem]'>
						<div className='flex flex-col items-start justify-start pr-[8.25rem] w-fit'>
							<button
                                onClick={() => setactive(1)}
								className={`${
									active === 1 ? 'profile_tab_active' : 'profile_tab'
								} mb-[4.12rem] w-max`}>
								Identity Documents
							</button>

							<button
                                onClick={() => setactive(2)}
								className={`${
									active === 2 ? 'profile_tab_active' : 'profile_tab'
								}`}>
								Organisations
							</button>
						</div>

						<div className=''>
							{active === 1 ? <IdentityDocsTab /> : <OrganizationsTab />}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
