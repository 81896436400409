import React, { useState } from 'react';
import svg6 from '../assets/svg/svg6.svg';
import svg7 from '../assets/svg/svg7.svg';
import { Link } from 'react-router-dom';

export default function ColumnServicesCard({
	item,
	open,
	handleActiveService,
}) {
	return (
		<>
			<button
				onClick={() => handleActiveService(item?.srcLink)}
				style={{
					border: '1px solid #ECECEC',
				}}
				className=' w-full bg-white mt-[1.25rem]'>
				<div className='flex items-center justify-between w-full px-[2rem] py-[1.47rem] border-l-[0.1875rem] border-grn'>
					<div className='flex flex-col justify-start items-start'>
						<span className='label_1'>{item?.srcName}</span>
						<span className='label_3 mt-[1rem]'>{item?.srcDescription}</span>
					</div>
					<span className={`text-[#222]`}>
						<img src={svg6} alt='svg6' />
					</span>
				</div>
			</button>
		</>
	);
}
