export default function HelpStep() {
	return (
		<>
			<div className='flex flex-col mt-[2.5rem]'>
				<div className='flex flex-col'>
					<span className='label_1 !font-[600]'>The Permanent Secretary</span>
					<span className='label_1 !font-[600]'>
						Ministry of Mines and Minerals Development
					</span>
					<span className='label_1 !font-[600]'>
						New Government Complex Building, Nasser Road
					</span>
					<span className='label_1 !font-[400]'>12th & 14th Floor</span>
					<span className='label_1 !font-[400]'>P.O Box 31969</span>
					<span className='label_1 !font-[400]'>Kamwala</span>
					<span className='label_1 !font-[400]'>LUSAKA</span>
				</div>

				<div className='flex flex-col mt-[1rem]'>
					<span className='label_1'>Contact Us</span>
					<span className='label_1 !font-[400]'>Email: info@mmmd.gov.zm</span>
				</div>
			</div>
		</>
	);
}
