import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	glbLoading: false,
	sideMenuCollapsed: false,
	isGrid: false,
};

export const globalSlice = createSlice({
	name: 'global',
	initialState,
	reducers: {
		handleSideMenuCollapse: (state, action) => {
			state.sideMenuCollapsed = action.payload;
		},
		setIsGrid: (state, action) => {
			state.isGrid = action.payload;
		},
	},
});

export default globalSlice.reducer;
export const { handleSideMenuCollapse, setIsGrid } = globalSlice.actions;
