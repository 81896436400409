import React from 'react';
import { useSelector } from 'react-redux';
import { getFirstAndLastWords, obfuscateEmail } from '../../utils';

function ProfileSummary() {
	

	const { user } = useSelector((state) => state.auth);

	return (
		<>
			<div className='px-[113px] mt-[40px] mb-[40px]'>
				<div className='border border-[#E0E0E0] h-[155px] rounded-[12px] py-10 px-[60px] flex items-center'>
					<div className='name_summary'>{getFirstAndLastWords(user?.given_name)}</div>

					<div className='h-[73.03px] w-[1px] ml-[43px] border border-[#BDBDBD]'></div>
					<div className='flex flex-col ml-[60px]'>
						<span className='typography_profile'>{user?.given_name}</span>
						<div className='mt-[8px] bg-[#388E3C] h-[35px] w-[86px] px-4 py-3 rounded-[4px] flex items-center justify-center text-white'>
							Citizen
						</div>
					</div>

					<div className='ml-[61px] flex flex-col'>
						<span className='label_2 !text-[#9E9E9E]'>NRC Number</span>
						<span className='label_1_profile '>123456/78/9</span>
					</div>

					<div className='ml-[80px] flex flex-col'>
						<span className='label_2 !text-[#9E9E9E]'>Email address</span>
						<span className='label_1_profile '>{obfuscateEmail(user?.email)}</span>
					</div>

					<div className='ml-[80px] flex flex-col'>
						<span className='label_2 !text-[#9E9E9E]'>Phone number</span>
						<span className='label_1_profile '>+{user?.family_name}</span>
					</div>
				</div>
			</div>
		</>
	);
}

export default ProfileSummary;
